import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TextField,
  Typography
} from '@mui/material';
import Loading from 'app/components/MatxLoading';
import { useEffect, useState } from 'react';

import {
  ArrowCircleLeft,
  ArrowLeftSharp,
  ArrowRightAlt,
  ArrowRightSharp,
  AutoFixNormal,
  CheckBoxRounded,
  CheckCircle,
  Circle,
  ClearAll,
  ClearOutlined,
  DoneAll,
  ImageNotSupportedOutlined,
  LightbulbCircleOutlined,
  Search,
  Send,
  StopOutlined
} from '@mui/icons-material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { green } from '@mui/material/colors';
import { H1, H3, H4, H6 } from 'app/components/Typography';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Link } from 'react-router-dom';
import { db } from '../../../../firebase';
import { get } from 'firebase/database';
import styled from '@emotion/styled';
import { StatusBlock } from '../history';
import { toast } from 'react-toastify';
import { MyLink } from 'app/views/dashboard/Analytics';

const CounterCircle = styled.span`
  padding: 1px 8px;
  font-size: 12px;
  border-radius: 50%;
  border: 2px solid #ccc;
  margin: 0 5px;
`;

const FirstStage = ({ setData, setCurrentStage, uid, presets, userData }) => {
  const [sites, setSites] = useState(null);
  const [loading, setLoading] = useState('');
  const [err, setErr] = useState(null);

  useEffect(() => {
    const getSites = async () => {
      const res = await getDocs(collection(db, 'userData', uid, 'wordpress_sites'));
      let nSites = [];
      res.docs.forEach((doc) => {
        nSites.push({ ...doc.data(), id: doc.id });
      });

      setSites(nSites);
    };

    getSites();
  }, []);

  const selectSite = async (site) => {
    setLoading(site.id);
    setErr(null);

    if (!site.settings.openai_key) {
      setErr('Please add your OpenAI API key in settings');
      setLoading('');
      return;
    }

    if (site.settings.selected_dc_cred === -1) {
      setErr('Please select a Discord credential in the site settings');
      setLoading('');
      return;
    }

    if (site.settings.selected_dc_cred >= userData.dc_creds.length) {
      setErr('Please update your discord credentials in settings');
      setLoading('');
      return;
    }

    const newSite = {
      ...site,
      settings: {
        ...site.settings,
        selected_dc_cred: userData.dc_creds[site.settings.selected_dc_cred]
      }
    };

    setData((d) => {
      return { ...d, site: newSite };
    });

    setLoading('');
    setCurrentStage(1);
  };

  if (!sites) return <Loading />;
  if (sites.length === 0) {
    return (
      <Box
        sx={{ textAlign: 'center' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
        width="100%"
        flexDirection="column"
      >
        <Typography variant="h5">You don't have any wordpress site yet</Typography>
        <br></br>
        <Typography variant="h6">
          Please add a site from
          <MyLink to="/wordpress_sites/choose"> Wordpress Sites </MyLink>
          to continue
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <H1>Select a Website</H1>
      <br></br>
      {err && (
        <Alert style={{ marginBottom: '10px' }} severity="error">
          {err}
        </Alert>
      )}

      {sites.map((site, i) => (
        <Stack
          key={i}
          direction="row"
          spacing={2}
          justifyContent={'space-between'}
          alignItems="center"
        >
          <LoadingButton
            style={{
              marginBottom: '10px'
            }}
            loading={loading === site.id}
            disabled={loading !== '' && loading !== site.id}
            variant="outlined"
            startIcon={<ArrowRightAlt />}
            onClick={() => selectSite(site)}
            fullWidth
          >
            <H4>{site.url.replace('https://', '').replace('/wp-json', '')}</H4>
          </LoadingButton>
        </Stack>
      ))}
    </div>
  );
};

const PostView = ({ site_id, post, submit, setSelectedPosts, selectedPosts, isMagicGen }) => {
  const [queueStatus, setQueueStatus] = useState(null);
  console.log(site_id);

  useEffect(() => {
    const getStatus = async () => {
      let q = query(
        collection(getFirestore(), 'wordpress_history'),
        where('site_doc', '==', site_id),
        where('post_id', '==', post.id),
        orderBy('createdAt', 'asc'),
        limit(1)
      );

      const res = await getDocs(q);

      if (res.docs.length > 0) {
        setQueueStatus(res.docs[0].data().status);
      }
    };

    getStatus();
  }, []);

  console.log(queueStatus);

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent={'space-between'}
      width="100%"
      border="1px solid #ccc"
      margin="10px 0px"
      padding="10px"
    >
      {!post.cover ? (
        <Avatar variant="square" style={{ width: '80px', height: '80px' }}>
          <ImageNotSupportedOutlined />{' '}
        </Avatar>
      ) : (
        <img width={'80px'} height={'80px'} src={post.cover} />
      )}

      <Stack direction="column" width="90%">
        <H4>
          <Link to={post.link} target="_blank">
            {post.title}
          </Link>
        </H4>

        <Stack direction="row" spacing={2}>
          <Typography variant="caption">Images: {post.image_count} </Typography>
          <Typography variant="caption">
            Last Processed: {post.alphonseai_date || 'N/A'}{' '}
          </Typography>
        </Stack>

        {queueStatus && (
          <div style={{ maxWidth: '100px', marginTop: '5px' }}>
            <StatusBlock status={queueStatus} />
          </div>
        )}
      </Stack>

      {!isMagicGen && (
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Button onClick={() => submit(post)} style={{ width: '10%', height: '40px' }}>
            <ArrowRightAlt />
          </Button>

          <Button
            onClick={() => {
              setSelectedPosts((sp) => {
                if (sp[post.id]) {
                  const newSP = { ...sp };
                  delete newSP[post.id];
                  newSP.count = newSP.count - 1;
                  newSP.posts = newSP.posts.filter((p) => p.id !== post.id);
                  return newSP;
                } else {
                  const newSP = { ...sp };
                  newSP[post.id] = post;
                  newSP.count = newSP.count + 1;
                  newSP.posts = [...newSP.posts, post];
                  return newSP;
                }
              });
            }}
            style={{ width: '10%', height: '40px' }}
          >
            {selectedPosts[post.id] ? <CheckBoxRounded /> : <Checkbox />}
          </Button>
        </Stack>
      )}

      {isMagicGen && (
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Button
            onClick={() => {
              window.open('/add_image_in_post/' + site_id + '/' + post.id, '_blank');
            }}
            style={{ width: '10%', height: '40px' }}
          >
            <AutoFixNormal />
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
const SearchPosts = ({ setData, data, setCurrentStage, presets }) => {
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [postType, setPostType] = useState('any');
  const [page, setPage] = useState(1);
  const [noPostPage, setNoPostPage] = useState(1);
  const [posts, setPosts] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedPosts, setSelectedPosts] = useState({ posts: [], count: 0 });
  const [isMagicGen, setIsMagicGen] = useState(false);
  const [isNoCoverOnly, setIsNoCoverOnly] = useState(false);
  const [lastServerPage, setLastServerPage] = useState([1]);

  useEffect(() => {
    setLoading(true);
    const getCategories = async () => {
      const fn = httpsCallable(getFunctions(), 'wordpressRequest');
      const res = await fn({
        command: 'getCategories',
        ...data.site
      });

      setCategories(res?.data || []);
    };
    if (window.location.pathname.includes('add_image_in_post')) setIsMagicGen(true);

    getCategories();
  }, []);

  useEffect(() => {
    searchPost();
  }, [page]);

  const submit = async (post) => {
    try {
      const objToSubmit = {
        ...data,
        post: {
          id: post.id,
          title: post.title,
          link: post.link
        }
      };

      setData(objToSubmit);

      setCurrentStage(2);
    } catch (e) {}
  };

  const submitAll = async () => {
    try {
      const objToSubmit = {
        ...data,
        post: selectedPosts.posts.map((p) => {
          return {
            id: p.id,
            title: p.title,
            link: p.link
          };
        })
      };

      setData(objToSubmit);

      setCurrentStage(2);
    } catch (e) {}
  };

  const searchPost = async () => {
    setLoading(true);

    try {
      const fn = httpsCallable(getFunctions(), 'wordpressRequest');
      const obj = {
        command: 'searchPost',
        site: data.site,
        search,
        postType,
        page,
        category: selectedCategory
      };

      const res = await fn(obj);
      // console.log(res.data);
      setPosts(res.data);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const searchNoCoverPost = async (isLoadPrev) => {
    setLoading(true);

    try {
      let pageLocal = lastServerPage[lastServerPage.length - 1];
      if (isLoadPrev) {
        pageLocal = await new Promise((resolve) => {
          setLastServerPage((p) => {
            const newP = p.slice(0, -1);
            resolve(newP[newP.length - 1]);
            return newP;
          });
        });
      }

      const fn = httpsCallable(getFunctions(), 'wordpressRequest');
      const obj = {
        command: 'searchPostOnlyNoCover',
        site: data.site,
        search,
        postType,
        page: pageLocal,
        category: selectedCategory
      };

      const res = await fn(obj);
      // console.log(res.data);
      setPosts(res.data.result);

      if (!isLoadPrev && res.data.result.length > 0) {
        setLastServerPage((p) => [...p, res.data.lastPage]);
      }
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const getAllPosts = async () => {
    setLoading(true);
    try {
      const fn = httpsCallable(getFunctions(), 'wordpressRequest');
      const obj = {
        command: 'getAllPosts',
        site: data.site,
        search,
        postType,
        noCover: isNoCoverOnly,
        category: selectedCategory
      };

      const res = await fn(obj);
      // console.log(res.data);
      setPosts(res.data.result);
    } catch (e) {
      toast.error('Something went wrong, please try again later.');
      console.log(e);
    }
    setLoading(false);
  };

  const selectAll = (e) => {
    let checked = e.target.checked;

    setSelectedPosts((p) => {
      let newSP = { ...p };
      posts.forEach((post) => {
        if (checked) {
          if (!newSP[post.id]) {
            newSP[post.id] = post;
            newSP.count++;
            newSP.posts.push(post);
          }
        } else {
          if (newSP[post.id]) {
            delete newSP[post.id];
            newSP.count--;
            newSP.posts = newSP.posts.filter((p) => p.id !== post.id);
          }
        }
      });

      return newSP;
    });
  };

  return (
    <div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <H1>Select a Post</H1>
        <FormControlLabel
          style={{}}
          control={
            <Checkbox
              onChange={(e) => {
                setIsNoCoverOnly(e.target.checked);
              }}
              checked={isNoCoverOnly}
            />
          }
          label="Only show posts without cover image"
        />
      </Stack>
      <br></br>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            label="Search Post Title"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fullWidth
            select
            label="Post Type"
            value={postType}
            onChange={(e) => setPostType(e.target.value)}
          >
            <MenuItem value="any">Any</MenuItem>
            <MenuItem value="publish">Published Posts</MenuItem>
            <MenuItem value="draft">Draft Posts</MenuItem>
            <MenuItem value="private">Private Posts</MenuItem>
            <MenuItem value="pending">Pending Posts</MenuItem>
            <MenuItem value="future">Future Posts</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fullWidth
            select
            label="Category"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <MenuItem value={0}>Any</MenuItem>
            {categories.map((cat, i) => (
              <MenuItem key={i} value={cat.id}>
                {cat.name} ({cat.count})
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={6} lg={2}>
          <LoadingButton
            style={{
              height: '100%',
              minHeight: '52px',
              maxHeight: '100%'
            }}
            disabled={loading}
            variant="contained"
            startIcon={<Search />}
            onClick={() => {
              setLastServerPage([1]);

              if (isNoCoverOnly) {
                searchNoCoverPost(false);
                setNoPostPage(1);
              } else if (page === 1) searchPost();
              else setPage(1);
            }}
            fullWidth
          >
            Search
          </LoadingButton>
        </Grid>
      </Grid>

      {!loading && posts && (
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={2}
          alignItems="center"
          marginTop="15px"
          width="100%"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            gap={2}
            alignItems="center"
            visibility={selectedPosts.count == 0 ? 'hidden' : 'visible'}
          >
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => setSelectedPosts({ posts: [], count: 0 })}
              startIcon={<ClearOutlined />}
            >
              Clear Selection
            </Button>
            <Badge badgeContent={selectedPosts.count} color="secondary">
              <Button
                disabled={loading}
                variant="contained"
                onClick={() => submitAll(selectedPosts)}
                startIcon={<Send />}
              >
                Generate All
              </Button>
            </Badge>
          </Stack>

          <Stack direction="row" gap={2}>
            <Button disabled={loading} onClick={() => getAllPosts()} startIcon={<DoneAll />}>
              Fetch All
            </Button>
            <FormControlLabel
              style={{}}
              control={<Checkbox onChange={selectAll} />}
              label="Select All"
            />
          </Stack>
        </Stack>
      )}
      <div style={{ position: 'relative' }}>
        {(posts === null || loading) && <Loading m={2} />}
        {!loading && (
          <Stack
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            {posts && posts.length == 0 && <H3>No Posts Found</H3>}

            {posts &&
              posts.map((post) => (
                <PostView
                  key={post.id}
                  post={post}
                  submit={submit}
                  setSelectedPosts={setSelectedPosts}
                  selectedPosts={selectedPosts}
                  site_id={data.site.id}
                  isMagicGen={isMagicGen}
                />
              ))}
          </Stack>
        )}
      </div>

      <Stack justifyContent="center">
        <Stack direction="row" justifyContent="center" alignItems="center">
          <LoadingButton
            disabled={(!isNoCoverOnly && page === 1) || (isNoCoverOnly && noPostPage === 1)}
            onClick={() => {
              if (isNoCoverOnly) {
                searchNoCoverPost(true);
                setNoPostPage((p) => p - 1);
              } else setPage((p) => p - 1);
            }}
            style={{ width: '100px' }}
            startIcon={<ArrowLeftSharp />}
          >
            Prev
          </LoadingButton>
          <H6>{isNoCoverOnly ? noPostPage : page}</H6>

          <LoadingButton
            disabled={posts && posts.length < 8}
            onClick={() => {
              if (isNoCoverOnly) {
                searchNoCoverPost(false);
                setNoPostPage((p) => p + 1);
              } else setPage((p) => p + 1);
            }}
            style={{ width: '100px' }}
            endIcon={<ArrowRightSharp />}
          >
            Next
          </LoadingButton>
        </Stack>
      </Stack>
    </div>
  );
};

const ThirdStage = ({ data, setCurrentStage, presets }) => {
  const [settings, setSettings] = useState(data.site.settings);
  const [loading, setLoading] = useState(false);
  const [configTabValue, setConfigTabValue] = useState('2');

  const submit = async () => {
    setLoading(true);

    try {
      //deep copy
      const obj = JSON.parse(JSON.stringify(data));

      const newSettings = { ...settings };
      newSettings.cover_preset_id = presets.find((p) => p.id === settings.cover_preset_id) || null;
      newSettings.article_preset_id =
        presets.find((p) => p.id === settings.article_preset_id) || null;

      obj.command = 'enquePost';
      obj.site.settings = newSettings;

      console.log('before Submit', obj);

      const fn = httpsCallable(getFunctions(), 'wordpressRequest');
      const res = await fn(obj);

      setCurrentStage(3);
    } catch (e) {
      console.log(e);
      toast.error(
        'Something went wrong, please try again later. Please check if your plan supports this feature.'
      );
    }

    setLoading(false);
  };

  return (
    <div>
      <H1>Review your settings</H1>

      <Stack
        display="flex"
        justifyItems="center"
        justifyContent="center"
        alignItems="center"
        marginTop={'12px'}
      >
        <Stack maxWidth="400px" gap="1rem">
          {/* <Alert severity="info">
            <Typography variant="caption">
              Any chnges you make here will not effect the saved settings of the
              site.
            </Typography>
          </Alert> */}
          <Typography variant="caption">
            {data.post.length || 1} post{data.post.length > 1 ? 's' : ''} selected for generation{' '}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setSettings({
                    ...settings,
                    remove_images: e.target.checked
                  });
                }}
                checked={settings.remove_images}
              />
            }
            label="Remove existing images from post (if any)"
          />

          <TextField
            select
            value={settings.cover_preset_id}
            onChange={(e) => {
              setSettings({
                ...settings,
                cover_preset_id: e.target.value
              });
            }}
            variant="outlined"
            label="Cover Image Preset"
            fullWidth
          >
            <MenuItem value="">Select a preset for cover image</MenuItem>
            {presets &&
              presets.map((preset) => (
                <MenuItem key={preset.id} value={preset.id}>
                  {preset.name}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            select
            value={settings.article_preset_id}
            onChange={(e) => {
              setSettings({
                ...settings,
                article_preset_id: e.target.value
              });
            }}
            variant="outlined"
            label="Article Image Preset"
            fullWidth
          >
            <MenuItem value="">Select a preset for article images</MenuItem>
            {presets &&
              presets.map((preset) => (
                <MenuItem key={preset.id} value={preset.id}>
                  {preset.name}
                </MenuItem>
              ))}
          </TextField>

          <TabContext value={configTabValue}>
            <TabList onChange={(e, v) => setConfigTabValue(v)}>
              <Tab label="Cover Image Config" value="2" />
              <Tab label="Article Image Config" value="1" />
            </TabList>

            <TabPanel value="1" style={{ padding: 0 }}>
              <Stack direction="row" gap="1rem">
                <TextField
                  value={settings.image_size[0]}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      image_size: [parseInt(e.target.value), settings.image_size[1]]
                    });
                  }}
                  variant="outlined"
                  label="Article Image Width"
                  fullWidth
                  type="number"
                />

                <TextField
                  value={settings.image_size[1]}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      image_size: [settings.image_size[0], parseInt(e.target.value)]
                    });
                  }}
                  variant="outlined"
                  label="Article Image Height"
                  fullWidth
                  type="number"
                />
              </Stack>

              <Stack gap="1rem" flexDirection="row" alignItems="center" marginTop={2}>
                <TextField
                  select
                  value={settings.aspect_ratio}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      aspect_ratio: e.target.value
                    });
                  }}
                  variant="outlined"
                  label="Article Image Aspect Ratio"
                  fullWidth
                  style={{ width: '50%' }}
                >
                  <MenuItem value="--ar 1:1">1:1</MenuItem>
                  <ListSubheader>Horizontal</ListSubheader>
                  <MenuItem value="--ar 3:2">3:2</MenuItem>
                  <MenuItem value="--ar 16:9">16:9</MenuItem>
                  <MenuItem value="--ar 5:4">5:4</MenuItem>
                  <MenuItem value="--ar 7:4">7:4</MenuItem>

                  <ListSubheader>Vertical</ListSubheader>
                  <MenuItem value="--ar 2:3">2:3</MenuItem>
                  <MenuItem value="--ar 9:16">9:16</MenuItem>
                  <MenuItem value="--ar 4:5">4:5</MenuItem>
                  <MenuItem value="--ar 7:4">7:4</MenuItem>
                </TextField>

                <Stack direction="row" alignItems="center" justifyContent="center" width="50%">
                  <FormControlLabel
                    style={{}}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setSettings({
                            ...settings,
                            activate_exif: e.target.checked
                          });
                        }}
                        checked={settings.activate_exif}
                      />
                    }
                    label="Activate EXIF"
                  />
                </Stack>
              </Stack>
            </TabPanel>

            <TabPanel value="2" style={{ padding: 0 }}>
              <Stack direction="row" gap="1rem">
                <TextField
                  value={settings?.cover_image_size?.[0]}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      cover_image_size: [
                        parseInt(e.target.value),
                        settings?.cover_image_size?.[1] || 1024
                      ]
                    });
                  }}
                  variant="outlined"
                  label="Cover Image Width"
                  fullWidth
                  type="number"
                />

                <TextField
                  value={settings?.cover_image_size?.[1]}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      cover_image_size: [
                        settings?.cover_image_size?.[0] || 1024,
                        parseInt(e.target.value)
                      ]
                    });
                  }}
                  variant="outlined"
                  label="Cover Image Height"
                  fullWidth
                  type="number"
                />
              </Stack>

              <Stack gap="1rem" flexDirection="row" alignItems="center" marginTop={2}>
                <TextField
                  select
                  value={settings.cover_image_aspect_ratio || '--ar 3:2'}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      cover_image_aspect_ratio: e.target.value
                    });
                  }}
                  variant="outlined"
                  label="Cover Image Aspect Ratio"
                  fullWidth
                  style={{ width: '50%' }}
                >
                  <MenuItem value="--ar 1:1">1:1</MenuItem>
                  <ListSubheader>Horizontal</ListSubheader>
                  <MenuItem value="--ar 3:2">3:2</MenuItem>
                  <MenuItem value="--ar 16:9">16:9</MenuItem>
                  <MenuItem value="--ar 5:4">5:4</MenuItem>
                  <MenuItem value="--ar 7:4">7:4</MenuItem>

                  <ListSubheader>Vertical</ListSubheader>
                  <MenuItem value="--ar 2:3">2:3</MenuItem>
                  <MenuItem value="--ar 9:16">9:16</MenuItem>
                  <MenuItem value="--ar 4:5">4:5</MenuItem>
                  <MenuItem value="--ar 7:4">7:4</MenuItem>
                </TextField>

                <Stack direction="row" alignItems="center" justifyContent="center" width="50%">
                  <FormControlLabel
                    style={{}}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setSettings({
                            ...settings,
                            activate_exif: e.target.checked
                          });
                        }}
                        checked={settings.activate_exif}
                      />
                    }
                    label="Activate EXIF"
                  />
                </Stack>
              </Stack>
            </TabPanel>
          </TabContext>

          <FormLabel id="radio-buttons-group-label">Number of Images to Generate</FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value={0}
              control={
                <Radio
                  checked={settings.h2_per_image == 0}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      h2_per_image: 0
                    });
                  }}
                />
              }
              label="Only Generate the cover photo"
            />
            <FormControlLabel
              value={2}
              control={
                <Radio
                  checked={settings.h2_per_image == 2}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      h2_per_image: 2
                    });
                  }}
                />
              }
              label="Generate 1 image for every 2 H2 tags"
            />
            <FormControlLabel
              value={3}
              control={
                <Radio
                  checked={settings.h2_per_image == 3}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      h2_per_image: 3
                    });
                  }}
                />
              }
              label="Generate 1 image for every 3 H2 tags"
            />
          </RadioGroup>

          <LoadingButton
            variant="contained"
            fullWidth
            color="primary"
            startIcon={<LightbulbCircleOutlined />}
            loading={loading}
            onClick={submit}
          >
            START GENERATING
          </LoadingButton>

          <Button variant="outlined" onClick={() => setCurrentStage(1)}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

const SingleGeneration = ({ uid, presets, userData, currentStage, setCurrentStage }) => {
  const [data, setData] = useState({ uid });

  return (
    <div>
      {currentStage === 0 && (
        <FirstStage
          setData={setData}
          setCurrentStage={setCurrentStage}
          uid={uid}
          presets={presets}
          userData={userData}
        />
      )}
      {currentStage > 0 && (
        <SearchPosts setData={setData} data={data} setCurrentStage={setCurrentStage} />
      )}

      <Dialog open={currentStage === 2 || currentStage === 3}>
        <DialogContent>
          {currentStage === 2 && (
            <ThirdStage data={data} setCurrentStage={setCurrentStage} presets={presets} />
          )}
          {currentStage === 3 && (
            <Box
              sx={{ textAlign: 'center' }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              padding={{ xs: '20px', md: '40px' }}
            >
              <br></br>
              <CheckCircle style={{ color: green[500], fontSize: '80px' }} />
              <Typography variant="h5">Task Queued</Typography>
              <Typography variant="h6">
                You can check the status in the WordPress{' '}
                <Link to="/wordpress_history">
                  {' '}
                  <u> history</u>
                </Link>
              </Typography>
              <br></br>
              <Button onClick={() => setCurrentStage(1)} startIcon={<ArrowCircleLeft />}>
                Generate More Post
              </Button>
              <br></br>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SingleGeneration;
