import React, { useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import ClearIcon from '@mui/icons-material/Clear';
import KeyIcon from '@mui/icons-material/Key';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import ImagesSettings from './ImageSettings/ImagesSettings';
import AutometicImage from './AutometicImage/AutometicImage';
import EXIF from './EXIF/EXIF';
import APIKeys from './APIKeys/APIKeys';
import {
  AddCircle,
  ArrowBack,
  CameraAlt,
  Instagram,
  Pinterest,
  Save,
  Timer
} from '@mui/icons-material';
import useSettings from 'app/hooks/useSettings';
import useAuth from 'app/hooks/useAuth';
import { useEffect } from 'react';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc
} from 'firebase/firestore';
import VerifyEmail from '../VerifyEmail';
import NotAvailableForPlan from '../wordpress/NotAvailableForPlan';
import ComingSoon from './ComingSoon';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import { merge } from 'lodash';
import ChooseSite from './ChooseSite';
import AddSiteDialog from './AddSiteDialog';
import { getFunctions, httpsCallable } from 'firebase/functions';

const contentLists = [
  {
    name: 'General',
    icon: LockIcon
  },
  {
    name: 'Scheduled Generation',
    icon: Timer
  },

  {
    name: 'Instagram',
    icon: Instagram
  },
  {
    name: 'Pinterest',
    icon: Pinterest
  },
  {
    name: 'EXIF',
    icon: CameraAlt
  },
  {
    name: 'Credentials',
    icon: KeyIcon
  }
];

const activateSite = async (siteId, activate) => {
  try {
    const fn = httpsCallable(getFunctions(), 'manageUserData');
    await fn({ command: 'activateSite', siteId, activate });
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const contentComponents = {
  General: ImagesSettings,
  'Scheduled Generation': AutometicImage,
  EXIF: EXIF,
  Credentials: APIKeys,
  Pinterest: ComingSoon,
  Instagram: ComingSoon
};

const Settings = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [currentContent, setCurrentContent] = useState('General');
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { user } = useAuth();

  const { settings, replaceAllPresets } = useSettings();
  const [userData, setUserData] = useState(null);
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('choose');
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [openSiteAdd, setOpenSiteAdd] = useState(false);
  const [loadingSites, setLoadingSites] = useState(true);

  const confirm = useConfirm();

  const open = Boolean(anchorEl);
  const CurrentComponent = contentComponents[currentContent];

  useEffect(() => {
    const getUserData = async () => {
      const dc = await getDoc(doc(getFirestore(), 'userData', user.uid));
      setUserData(dc.data());

      //fetch sites
      const res = await getDocs(
        collection(getFirestore(), 'userData', user.uid, 'wordpress_sites')
      );
      let nSites = [];
      res.docs.forEach((doc) => {
        nSites.push({ ...doc.data(), id: doc.id });
      });

      nSites.sort((a, b) => a.url.localeCompare(b.url));

      setSites(nSites);
      setLoadingSites(false);
    };

    const getPresets = async () => {
      const ref = collection(getFirestore(), 'userData', user.uid, 'presets');
      const snapshot = await getDocs(ref);
      let nPrompts = [];
      snapshot.forEach((doc) => {
        nPrompts.push({ ...doc.data(), id: doc.id });
      });
      replaceAllPresets(nPrompts);
    };

    if (!settings.presets) getPresets();

    getUserData();
  }, []);

  useEffect(() => {
    setSelectedSite(params.siteID || 'choose');
  }, [params]);

  const handleContent = (item) => {
    setCurrentContent(item.name);
    if (isMobile) {
      setAnchorEl(null); // Close popover on mobile after selecting content
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSite = (data) => {
    if (selectedSite === 'default') {
      if (typeof data === 'function') {
        setUserData((p) => ({ ...p, ...data(p.default_wp_settings) }));
      } else {
        const updated = merge(
          userData,
          data?.settings ? { default_wp_settings: data.settings } : data
        );
        setUserData({ ...updated });
      }
    } else {
      if (typeof data === 'function') {
        setSites((p) => p.map((s) => (s.id === selectedSite ? data(s) : s)));
      } else setSites((p) => p.map((s) => (s.id === selectedSite ? merge(s, data) : s)));
    }
  };

  const saveChanges = async () => {
    setSaving(true);

    try {
      if (selectedSite === 'default') {
        await updateDoc(doc(getFirestore(), 'userData', user.uid), {
          ...userData
        });
      } else {
        const site = sites.find((s) => s.id === selectedSite);
        await updateDoc(
          doc(getFirestore(), 'userData', user.uid, 'wordpress_sites', selectedSite),
          { ...site }
        );

        await activateSite(site.id, site.active);
      }

      toast.success('Settings saved successfully');
      handleClose();
    } catch (e) {
      console.error(e);
      toast.error('Error saving settings');
    }

    setSaving(false);
  };

  const deleteSite = async () => {
    if (selectedSite === 'default') {
      toast.error('Cannot delete default site');
      return;
    }

    setDeleting(true);
    try {
      await confirm({
        description: 'Are you sure you want to delete this site?',
        title: 'Delete Site',
        confirmationText: 'Yes, Delete',
        cancellationText: 'No'
      });

      await deleteDoc(doc(getFirestore(), 'userData', user.uid, 'wordpress_sites', selectedSite));

      setSelectedSite('default');
      setSites((p) => p.filter((s) => s.id !== selectedSite));
      toast.success('Site deleted successfully');
    } catch (e) {
      console.error(e);
      toast.error('Error deleting site');
    }
    setDeleting(false);
  };

  if (!user.emailVerified) {
    return <VerifyEmail />;
  }

  if (!user.plan.wp_rest_api) {
    return <NotAvailableForPlan />;
  }

  if (selectedSite === 'choose') {
    return <ChooseSite sites={sites} setSites={setSites} loadingSites={loadingSites} />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AddSiteDialog open={openSiteAdd} setOpen={setOpenSiteAdd} setSites={setSites} />
      <Typography variant="h5" marginTop={isMobile ? 2 : 1} marginBottom={isMobile ? 2 : 1}>
        <IconButton onClick={() => navigate(-1)} title="Back">
          <ArrowBack />
        </IconButton>
        Wordpress Sites
      </Typography>
      {isMobile && (
        <Stack direction="row" justifyItems="center" spacing={2} sx={{ margin: '16px 0 0 16px' }}>
          <TextField
            select
            label="settings for"
            fullWidth
            size="small"
            defaultValue="default"
            value={selectedSite}
            onChange={(e) => navigate(`/wordpress_sites/${e.target.value}`)}
          >
            <MenuItem value="default">Default Site Settings</MenuItem>
            {sites.map((site) => (
              <MenuItem key={site.id} value={site.id}>
                {site.url}
              </MenuItem>
            ))}
          </TextField>

          <IconButton
            variant="contained"
            color="primary"
            onClick={() => setOpenSiteAdd(true)}
            title="Add new site"
          >
            <AddCircle />
          </IconButton>

          <IconButton onClick={handleClick} title="menu">
            {open ? <ClearIcon /> : <SlideshowIcon sx={{ transform: 'rotate(90deg)' }} />}
          </IconButton>
        </Stack>
      )}
      <Grid container spacing={3}>
        {!isMobile && (
          <Grid item xs={2}>
            <Card
              className="contentListBar"
              sx={{
                minHeight: '100%',
                padding: '16px 8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <div style={{ marginBottom: '10px' }}>
                  <TextField
                    select
                    label="settings for"
                    fullWidth
                    size="small"
                    defaultValue="default"
                    value={selectedSite}
                    onChange={(e) => navigate(`/wordpress_sites/${e.target.value}`)}
                  >
                    <MenuItem value="default">Default Site Settings</MenuItem>
                    {sites.map((site) => (
                      <MenuItem key={site.id} value={site.id}>
                        {site.url}
                      </MenuItem>
                    ))}
                  </TextField>

                  <Button
                    onClick={() => setOpenSiteAdd(true)}
                    style={{ marginTop: '10px' }}
                    startIcon={<AddCircle />}
                  >
                    Add new site
                  </Button>
                </div>

                <Divider />
                <ul style={{ paddingLeft: '0', margin: '0', marginTop: '10px' }}>
                  {contentLists.map((content, index) =>
                    content.name === 'Scheduled Generation' && selectedSite === 'default' ? null : (
                      <li
                        key={content.name}
                        onClick={() => handleContent(content)}
                        style={{
                          listStyle: 'none',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          padding: '8px',
                          paddingLeft: '16px',
                          color: currentContent === content.name ? '#1976d2' : '',
                          backgroundColor: currentContent === content.name ? '#f5f5f5' : '#fff',
                          borderLeft:
                            currentContent === content.name ? '4px solid blue' : '4px solid #fff'
                        }}
                      >
                        <content.icon
                          fontSize="small"
                          sx={{
                            color: currentContent === content.name ? '#1976d2' : '#34314c8a'
                          }}
                        />
                        <span style={{ marginLeft: '6px' }}>{content.name}</span>
                      </li>
                    )
                  )}
                </ul>
              </Box>
              <div>
                <Divider style={{ marginTop: '20px' }} />
                <LoadingButton
                  loading={saving}
                  onClick={saveChanges}
                  size="small"
                  variant="outlined"
                  fullWidth
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    marginTop: '16px'
                  }}
                  startIcon={<Save fontSize="small" />}
                >
                  Save Changes
                </LoadingButton>
                <LoadingButton
                  loading={deleting}
                  onClick={deleteSite}
                  variant="outlined"
                  fullWidth
                  color="error"
                  size="small"
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    marginTop: '8px'
                  }}
                  startIcon={<DeleteOutlineIcon fontSize="small" />}
                >
                  Delete Wordpress
                </LoadingButton>
              </div>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} md={9}>
          <div style={{ backgroundColor: 'whitesmoke', maxWidth: '1100px' }}>
            {CurrentComponent && (
              <CurrentComponent
                site={
                  selectedSite === 'default'
                    ? userData?.default_wp_settings
                      ? { settings: userData.default_wp_settings }
                      : null
                    : sites.find((s) => s.id === selectedSite)
                }
                updateSite={updateSite}
                userData={userData}
                selectedSite={selectedSite}
              />
            )}
          </div>
        </Grid>
      </Grid>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Card
          className="contentListBar"
          sx={{
            minWidth: '200px',
            padding: '16px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            {contentLists.map((content, index) => (
              <ul key={index} style={{ paddingLeft: '0', margin: '0' }}>
                <li
                  onClick={() => handleContent(content)}
                  style={{
                    listStyle: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px',
                    paddingLeft: '16px',
                    color: currentContent === content.name ? '#1976d2' : 'black',
                    backgroundColor: currentContent === content.name ? '#f5f5f5' : '#fff',
                    borderLeft:
                      currentContent === content.name ? '4px solid blue' : '4px solid #fff'
                  }}
                >
                  <content.icon
                    fontSize="small"
                    sx={{
                      color: currentContent === content.name ? '#1976d2' : '#34314c8a'
                    }}
                  />
                  <span style={{ marginLeft: '6px' }}>{content.name}</span>
                </li>
              </ul>
            ))}
          </Box>

          <LoadingButton
            loading={saving}
            onClick={saveChanges}
            fullWidth
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              paddingLeft: '16px'
            }}
          >
            <Save sx={{ marginRight: '2px', marginBottom: '1.5px' }} fontSize="small" /> Save
            Changes
          </LoadingButton>

          <LoadingButton
            loading={deleting}
            onClick={deleteSite}
            fullWidth
            color="error"
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              paddingLeft: '16px'
            }}
          >
            <DeleteOutlineIcon
              sx={{ marginRight: '2px', marginBottom: '1.5px' }}
              fontSize="small"
            />{' '}
            Delete Wordpress
          </LoadingButton>
        </Card>
      </Popover>
    </Box>
  );
};

export default Settings;
