import {
  Box,
  Button,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from '@mui/material';
import Loading from 'app/components/MatxLoading';
import { H2 } from 'app/components/Typography';
import { useEffect, useState } from 'react';
import './search.css';
import { AddCircle, ArrowForward, Circle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AddSiteDialog from './AddSiteDialog';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
  position: 'relative'
}));

const Searchbar = ({ setSearch }) => {
  return (
    <div class="search-box">
      <input
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        class="search-text"
        type="text"
        placeholder="Search by site"
      />
      <a href="#" class="search-btn">
        <Icon>search</Icon>
      </a>
    </div>
  );
};

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } }
  }
}));

const ChoseSite = ({ sites, setSites, loadingSites }) => {
  const [filteredData, setFilteredData] = useState(null);
  const [search, setSearch] = useState('');
  const [op, setOp] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (sites) {
      setFilteredData(
        sites.filter((item) => {
          if (search === '') return true;
          else if (item.url.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
        })
      );
    }
  }, [search, sites]);

  return (
    <div>
      <AddSiteDialog open={op} setOpen={setOp} setAddedSite={setSites} />
      <FlexBox flexDirection="row" justifyContent="space-between!important" gap="15px">
        <H2 style={{ marginLeft: '12px' }}>Choose Site</H2>
        <Button onClick={() => setOp(true)} size="small" startIcon={<AddCircle />} color="primary">
          Add
        </Button>
      </FlexBox>
      <FlexBox>
        <Box
          sx={{ padding: '10px 20px' }}
          md={{ padding: '15px 25px' }}
          style={{ maxWidth: '1100px' }}
        >
          <TableContainer>
            <FlexBox style={{ justifyContent: 'right' }}>
              <Searchbar setSearch={setSearch} />
            </FlexBox>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="30%">
                    <b>Site</b>
                  </TableCell>
                  <TableCell align="center" width="20%">
                    <b>Bulk Generation</b>
                  </TableCell>
                  <TableCell align="center" width="20%">
                    <b>Instagram</b>
                  </TableCell>
                  <TableCell align="center" width="20%">
                    <b>Pinteres</b>
                  </TableCell>
                  <TableCell align="right" width="10%">
                    <b>Details</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loadingSites && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={5}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                )}

                {filteredData && filteredData.length === 0 && !loadingSites && (
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={5}>
                      <div
                        style={{
                          width: '100%',
                          height: '50px',
                          marginTop: '20px',
                          textAlign: 'center'
                        }}
                      >
                        <h3>No sites found</h3>
                      </div>
                    </TableCell>
                  </TableRow>
                )}

                {filteredData && filteredData.length > 0 && !loadingSites && (
                  <TableRow>
                    <TableCell
                      onClick={(e) => {
                        navigate(`/wordpress_sites/default`);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <Typography>Default Site Settings</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>-</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>-</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>-</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          navigate(`/wordpress_sites/default`);
                        }}
                      >
                        <ArrowForward />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}

                {filteredData &&
                  filteredData.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell
                        onClick={(e) => {
                          navigate(`/wordpress_sites/${item.id}`);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {item.url.replace('https://', '').replace('/wp-json', '')}
                      </TableCell>
                      <TableCell align="center">
                        <Typography color={item.active ? 'primary' : 'error'}>
                          {item.active ? 'Active' : 'Inactive'}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => navigate(`/wordpress_sites/${item.id}`)}
                        >
                          <ArrowForward />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Box>
      </FlexBox>
    </div>
  );
};

export default ChoseSite;
